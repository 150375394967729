

















import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import AiLoadingOverlay from '@/tasks/components/AiLoadingOverlay.vue';

export default {
  name: 'OleMissL5PostLab',
  components: {AiLoadingOverlay, STextarea},
  mixins: [DynamicQuestionMixin()],
  props: {
    isSubmitting: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      inputs: {
        part5_1: null,
        part5_2: null,
        part5_3: null,
        part5_4: null,
        part5_5: null,
        part5_6: null,
        part5_7: null,
        part5_8: null,
      },
      questions: [
        {
          text: 'a) What material did your group determine the object to be made from?',
          inputName: 'part5_1',
        },
        {
          text: 'b) Explain why your group determined that this procedure was the best choice for determining the material of the object.',
          inputName: 'part5_2',
        },
        {
          text: 'c) What parts of the procedure could you have done differently, given unlimited time and money?',
          inputName: 'part5_3',
        },
        {
          text: 'd) The news article states that an anonymous Space-M employee gave a list of materials used to build the rocket. He stated that the list was not exhaustive. Do you trust what the employee has to say about the materials? Why or why not?',
          inputName: 'part5_4',
        },
        {
          text: 'e) Sometimes you need to use someone else’s measurements or calculations. Do you trust the measurements that Humphrey took? Why or why not? Did this play a role in your determination of what material the piece of equipment was made from?',
          inputName: 'part5_5',
        },
        {
          text: 'f) What did you do (if anything) to mitigate the effects of error? What more could you have done to mitigate the effects of error?',
          inputName: 'part5_6',
        },
        {
          text: 'g) The object that was found is a piece from a tube that carries cold liquid throughout the rocket. It sits near the heat of the rocket engine and is almost always exposed to the weather. If you had to choose one single most important physical or chemical property of this piece, what would it be? Why this property over any others?',
          inputName: 'part5_7',
        },
        {
          text: 'h) Based on your answers for questions 1 and 8, does it make sense to make this piece of equipment out of this material? Why or why not? If not, what is a substance that you would choose to make the piece of equipment from? Explain your answer.',
          inputName: 'part5_8',
        },
      ],
    };
  },
};
